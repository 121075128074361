import React from 'react'
import { Link } from 'gatsby'
import { Columns, Container, Hero } from 'react-bulma-components'

function CustomerApps() {
  return (
    <Hero className="">
      <Hero.Body>
        <Container className="content">
          <Columns className="is-centered">
            <Columns.Column className="is-10 has-text-centered">
              <h2 className="is-size-3-mobile">
                Three ways to make{' '}
                <span className="has-text-success">better customer apps</span>
              </h2>
              <br />
              <br />
            </Columns.Column>
          </Columns>
          <Columns className="is-centered">
            <Columns.Column className="is-3">
              <h3>User Research</h3>
              <p>
                Ever wondered how your customers think? User research helps you
                find the answers. It enables you to understand the pain points
                and desires of your customers. Our UX team carry out user
                interviews, focus groups, surveys and other research activities.
                All this awesome insight improves the design of your product.
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Service Design</h3>
              <p>
                Your service offering starts before your app is downloaded, and
                continues after customers use your app. We look at the big
                picture so we can be sure everything fits together nicely and
                customers have an easy time interacting with your business.
                We'll take a good look at what you're doing and design a service
                that puts customers at the centre.
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>App Design</h3>
              <p>
                You know when you're using a well-designed app. It feels simple.
                Delightful, even. It anticipates your next move and still works
                even when you have flakey phone reception. Our UX and UI team
                care about these things. And because we're mobile specialists,
                we know how to design an app that feels like an app should.
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column className="is-offset-2 is-8 has-text-centered">
              <p>
                <br />
                <Link
                  to="/work/services"
                  className="button is-primary is-leafy is-medium is-primary"
                >
                  View all our services
                </Link>
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default CustomerApps
