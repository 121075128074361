import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import WorkNav from 'components/work-nav'
import ArrowLink from 'components/link-with-arrow'
import Testimonials from 'components/testimonials'
import Layout from 'components/layout'
import Clients from 'components/clients'
import Customers from 'components/customers'
import AnnaQuote from 'components/annaQuote'
import CaseStudyBlog from 'components/casestudyBlog'
import WorkHero from 'components/workHero'

// Page Components
import CustomerApps from './components/customer-apps'

function WorkDigitalTransformation({
  data: { caseStudies, page, testimonials },
}) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <WorkNav />
      <WorkHero page={page} />
      <hr />
      <Testimonials testimonials={testimonials} />
      <Clients />
      <AnnaQuote />
      <CustomerApps />
      <Customers />
      <CaseStudyBlog title="consumer app" caseStudies={caseStudies} />
      <hr />
      <Section>
        <Container className="has-text-centered">
          <ArrowLink to="/work/workforce-productivity" className="is-info">
            Next: Workforce Productivity
          </ArrowLink>
        </Container>
      </Section>
    </Layout>
  )
}

WorkDigitalTransformation.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WorkDigitalTransformation
